@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
}

body {
  font-family: "Open Sans";
}

#offer {
  background-image: url("../src/assets/images/Offer.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.no-scrollbar {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

/* /////////////////////////// */

* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}

/* my-[0.5rem] bg-[#F15E7E] text-[white] py-[0.6rem] px-[2rem] rounded-[4px] */
/* .specialbutton {
      margin-top: "0.5rem !important";
      margin-bottom: "0.5rem !important";
      background-color: "#F15E7E !important";
      color: "white !important";
      padding-top: "0.6rem !important";
      padding-bottom: "0.6rem !important" ;
      border-radius: "4px !important";
  } */

.scrollbar::-webkit-scrollbar {
  width: 0.3rem;

  height: 0.3rem;
}

/* Track */

::-webkit-scrollbar-track {
  border-radius: 5px;

  background-color: #d9d9d9;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #8c8c8c;

  border-radius: 5px;
}

.my-select-container .ant-select .ant-select-selector {
  border-radius: 3px;
}

.pac-container {
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
  font-family: Arial, sans-serif;
  z-index: 1000;

  margin-top: 8px;
  margin-left: -35px;
  width: 24% !important;
}

@media screen and (max-width: 750px) {
  .pac-container {
    margin-top: 6px;
    margin-left: -34px;
    width: 91% !important;
  }
}
@media screen and (max-width: 400px) {
  .pac-container {
    margin-top: 6px;
    margin-left: -34px;
    width: 78% !important;
  }
}

.hideLongTypography {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.hideLongTypography2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.hideLongTypography1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.loader {
  height: 4px;
  width: 95.5%;
  --c: no-repeat linear-gradient(#0057a3 0 0);
  background: var(--c), var(--c), #d7b8fc;
  background-size: 60% 100%;
  animation: l16 2s infinite;
}
@keyframes l16 {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}

.shine {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 90%;
  height: 200%;
  background: rgba(255, 255, 255, 0.8);
  transform: rotate(45deg);
  opacity: 0;
  animation: shineAnimation 1.5s infinite;
}

@keyframes shineAnimation {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: rotate(45deg) translate(50%, 50%);
  }
}
